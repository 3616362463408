var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "mb-2"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Detail",
      "active": ""
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "9",
      "md": "10",
      "sm": "12"
    }
  }, [_c('b-card', [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('td', [_vm._v("Nama Aset")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.asset ? _vm.penyimpanan.asset.nama : ''))])]), _c('tr', [_c('td', [_vm._v("Kode")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.asset ? _vm.penyimpanan.asset.kode : ''))])]), _c('tr', [_c('td', [_vm._v("Jenis Aset")]), _c('th', [_vm.penyimpanan.asset ? _c('div', [_vm._v(" " + _vm._s(_vm.penyimpanan.asset.jenis == 1 ? 'TETAP' : 'BHP') + " ")]) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("Kategori")]), _c('th', [_vm.penyimpanan.asset ? _c('div', [_vm._v(" " + _vm._s(_vm.penyimpanan.asset.kategori ? _vm.penyimpanan.asset.kategori.kategori : '') + " ")]) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("Umur")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.asset ? _vm.penyimpanan.asset.umur : ''))])]), _c('tr', [_c('td', [_vm._v("Volume")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.asset ? _vm.penyimpanan.asset.volume : ''))])]), _c('tr', [_c('td', [_vm._v("Harga Dasar ")]), _c('th', [_vm._v("Rp " + _vm._s(_vm.penyimpanan.asset ? _vm.formatRupiah(_vm.penyimpanan.asset.harga_dasar) : 0))])]), _c('tr', [_c('td', [_vm._v("Keterangan")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.asset ? _vm.penyimpanan.asset.keterangan : ''))])]), _c('tr', [_c('td', [_vm._v("Stok")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.stok))])]), _c('tr', [_c('td', [_vm._v("Satuan")]), _c('th', [_vm.penyimpanan.asset ? _c('div', [_vm._v(" " + _vm._s(_vm.penyimpanan.asset.satuan ? _vm.penyimpanan.asset.satuan.satuan : '') + " ")]) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("Lokasi Gudang")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.gudang ? _vm.penyimpanan.gudang.nama_gudang : ''))])]), _c('tr', [_c('td', [_vm._v("Lokasi Aset")]), _c('th', [_vm._v(_vm._s(_vm.penyimpanan.blok ? _vm.penyimpanan.blok.blok : ''))])])])])], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Riwayat Penyimpanan"
    }
  }, [_c('table-riwayat-penyimpanan', {
    on: {
      "showLoading": _vm.toggleLoading
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }